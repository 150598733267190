/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, Menu, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Image, Subtitle, Text, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <Column name={"gg2a9euyd3l"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} layout={"l16"}>
        </Column>


        <Column name={"uvod"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim3" anim={"3"}>
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/\">Tribečské múzeum v Topoľčanoch</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"1dgwxpra01l"} style={{"backgroundColor":"rgba(245,166,35,1)"}}>
        </SeparateLine>


        <Column className="--menu css-cnwws bg--center" menu={true} name={"menu"} style={{"paddingTop":0,"marginBottom":0,"paddingBottom":48.046875}} parallax={false} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/7746/a50a300accc640b7abce9e52e1c4e977_s=3000x_.jpg);
    }
  
    `}>
          
          <Menu className="--full" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box --hvr5 js-anim  --anim5" anim={"5"} style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--3 fs--48" style={{"paddingTop":4,"paddingBottom":0}} content={"<div></div><span style=\"font-weight: bold; color: rgb(255, 255, 255);\"><br>Služby <br>pre verejnosť</span>"}>
              </Title>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox style={{"marginTop":0,"paddingTop":0,"marginBottom":35.421875,"paddingBottom":45.7421875}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 btn-box--invert ls--02 lh--1" href={"/"} style={{"style":5}} target={""} content={"<div><br></div><a href=\"/\">Hlavná stránka</a><br>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/kontakt"} style={{"style":5}} target={""} content={"<div>Kontakt</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/kniznica"} style={{"style":5}} target={""} content={"<div>Knižnica</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/projekty"} style={{"style":5}} target={""} content={"<div>Projekty</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/podujatia"} style={{"style":5}} target={""} content={"<div>Podujatia</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/#vystavy"} style={{"style":5}} target={""} content={"<div>Výstavy</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" href={"/muzeum-online#vystavy"} style={{"style":5}} target={""} content={"<div>Múzeum online</div>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

          <Menu className="--full" style={{"maxWidth":520}} fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box" style={{"paddingTop":0,"paddingBottom":0}}>
            </ColumnWrapper>

            <ColumnWrapper className="menu-logo-box" style={{"paddingTop":0,"paddingBottom":0}}>
            </ColumnWrapper>

            <ColumnWrapper className="menu-logo-box --hvr5" style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/84a41b1070c64df3bf6e5f948a183a95_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"ponuka-tlacovin"} style={{"paddingTop":0,"paddingBottom":5.109375,"backgroundColor":"rgba(61, 61, 61, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape5 --style2 js-anim  --anim4" anim={"4"} animS={null} style={{"paddingTop":0,"paddingBottom":10.2734375,"backgroundColor":"rgba(147, 56, 124, 0.6)"}}>
              
              <Button className="btn-box btn-box--style2 ff--3 fs--13 w--900 ls--02" href={"/ponuka-tlacovin"} style={{"marginTop":11.578125,"paddingBottom":2.53125}} content={"Ponuka tlačovín"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"adbh17fxu9"} style={{"paddingTop":0,"paddingBottom":16.1875,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --hvr2 --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5">
              
              <Image src={"https://cdn.swbpg.com/t/7746/492702f8b6dc46c082e716d956a3a8d0_s=860x_.png"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":50}} srcSet={"https://cdn.swbpg.com/t/7746/492702f8b6dc46c082e716d956a3a8d0_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/492702f8b6dc46c082e716d956a3a8d0_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/492702f8b6dc46c082e716d956a3a8d0_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/492702f8b6dc46c082e716d956a3a8d0_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pr--40" name={"lxkd7ivsp1"} style={{"paddingTop":0,"paddingBottom":26.171875,"backgroundColor":"rgba(61,61,61,1)"}}>
          
          <ColumnWrap className="column__flex --left el--1" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/t/7746/c8e4c2f3f1d7411b8c8471fc859bc9c8_s=860x_.png"} use={"page"} href={"/ponuka-tlacovin#mapa"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":668}} srcSet={"https://cdn.swbpg.com/t/7746/c8e4c2f3f1d7411b8c8471fc859bc9c8_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/c8e4c2f3f1d7411b8c8471fc859bc9c8_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/c8e4c2f3f1d7411b8c8471fc859bc9c8_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/c8e4c2f3f1d7411b8c8471fc859bc9c8_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7746/c8e4c2f3f1d7411b8c8471fc859bc9c8_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"enzfjqcoqts"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Subtitle className="subtitle-box subtitle-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖ Špecializovaná knižnica</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"zfshuzjtaa"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}}>
        </SeparateLine>


        <Column name={"4jt438hd14u"} style={{"paddingTop":21,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--20 w--300 lh--16" content={"<span style=\"color: rgb(255, 255, 255);\">Knižnica múzea disponuje rozsiahlym fondom odbornej literatúry ( 9 429 ks) a odborných časopisov z rôznych oblastí prírodných a spoločenských vied. <br>Vo fonde má zastúpenie i regionálna literatúra. Záujemcovia o knižničné služby majú možnosť prezenčne študovať odbornú literatúru, odborné časopisy, regionálne noviny a iné tlačoviny. Na požiadanie im budú vyhotovené kópie písomných materiálov.\nPre verejnosť je knižnica otvorená</span><span style=\"color: rgb(240, 240, 242);\"> </span><span style=\"font-weight: bold;\">v utorok a vo štvrtok od 8.00 h do 14.00 h.</span><span style=\"color: rgb(240, 240, 242);\">\nPoplatok za služby poskytované knižnicou: </span><span style=\"font-weight: bold;\">6,00 €</span> <span style=\"font-weight: bold;\">na jeden rok </span><span style=\"color: rgb(240, 240, 242);\">alebo </span><span style=\"font-weight: bold;\">3 € na pol roka.</span><span style=\"color: rgb(240, 240, 242);\">\nV poplatku je zahrnuté aj prípadné poskytnutie študijného materiálu z dokumentačného fondu múzea, vyhľadanie obrazových záznamov z fotoarchívu múzea, a tiež odborná metodická pomoc knihovníčky/dokumentátorky.\nKopírovanie písomných materiálov a vyhotovenie digitálnych obrazových záznamov je spoplatnené podľa osobitného cenníka.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"badatelsky-poriadok"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape5 js-anim  --anim4" anim={"4"} animS={null} style={{"backgroundColor":"rgba(147, 56, 124, 0.6)"}}>
              
              <Button className="btn-box btn-box--style2 ff--3 fs--13 ls--02" href={"https://cdn.swbpg.com/d/7746/kniznicny-a-vypozicny-poriadok.pdf"} content={"<span style=\"font-weight: bold;\">Knižničný poriadok</span>"} document={"e2cd723603e2498dafa0da305f289280"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" name={"baoh8vw53yf"} style={{"paddingTop":0,"backgroundColor":"rgba(61, 61, 61, 1)"}}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"smzt3hqwdw"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Subtitle className="subtitle-box subtitle-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖</span>&nbsp;<span style=\"color: rgb(245, 166, 35);\">Sprístupňovanie zbierkových predmetov&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"3xqay1b8edv"} style={{"paddingTop":27,"paddingBottom":23.1875,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--20 w--300 lh--16" content={"<span style=\"color: rgb(255, 255, 255);\">Zbierkové predmety sa sprístupňujú na prezenčné študijné účely so zámerom ich využitia na publikačnú, edičnú, vedeckovýskumnú a inú odbornú činnosť. Sprístupnenie zbierkových predmetov sa zrealizuje</span><span style=\"color: rgb(240, 240, 242);\"> </span><span style=\"font-weight: bold;\">na základe podanej písomnej žiadosti bádateľa</span><span style=\"color: rgb(240, 240, 242);\"> </span><span style=\"font-weight: bold;\">minimálne 5 dní pred termínom štúdia,</span><span style=\"color: rgb(240, 240, 242);\">&nbsp;</span><span style=\"background-color: transparent; letter-spacing: -0.01em; color: rgb(240, 240, 242);\">v ktorej bude uvedený účel štúdia, téma a zameranie zbierkových predmetov. Zbierkové predmety budú bádateľovi poskytnuté až po odsúhlasení žiadosti riaditeľkou múzea. Jej schváleniu podlieha aj žiadosť o vyhotovenie obrazových záznamov zbierkových predmetov. Ďalšie zásady sprístupňovania zbierkových predmetov na prezenčné štúdium upravuje Bádateľský poriadok. Poplatok za služby spojené so sprístupňovaním zbierkových predmetov:</span><span style=\"background-color: transparent; color: var(--color-variable-1); letter-spacing: -0.01em; font-weight: bold;\">&nbsp;1</span><span style=\"background-color: transparent; color: var(--color-variable-1); letter-spacing: -0.01em; font-weight: bold;\">&nbsp;€&nbsp;</span><span style=\"font-weight: bold;\">za každú (aj začatú) hodinu.</span><span style=\"color: rgb(240, 240, 242);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"badatelsky-poriadok"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape5 js-anim  --anim4" anim={"4"} animS={null} style={{"backgroundColor":"rgba(147, 56, 124, 0.6)"}}>
              
              <Button className="btn-box btn-box--style2 ff--3 fs--13 ls--02" href={"https://cdn.swbpg.com/d/7746/badatelsky-poriadok.pdf"} content={"<span style=\"font-weight: bold;\">Bádateľský poriadok</span>"} document={"612b4475e789434fa958b8dd8f137f28"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"baoh8vw53yf"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"hxt3h7iezph"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Subtitle className="subtitle-box subtitle-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖</span>&nbsp;<span style=\"color: rgb(245, 166, 35);\">Odborné konzultácie </span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"3xqay1b8edv"} style={{"paddingTop":30,"paddingBottom":19.875,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--20 w--300 lh--16" content={"<span style=\"color: rgb(255, 255, 255);\">Na požiadanie za vedeckým alebo študijným účelom odborní zamestnanci múzea z jednotlivých vedných odborov poskytujú odborné konzultácie. Poskytovanie odborných konzultácií je</span><span style=\"color: rgb(240, 240, 242);\"> </span><span style=\"font-weight: bold;\">v utorok a</span> <span style=\"font-weight: bold;\">vo štvrtok od 8.00 h do 14.00 h</span>.<span style=\"color: rgb(240, 240, 242);\"> </span><span style=\"color: rgb(255, 255, 255);\">Poplatok za odborné konzultácie:</span><span style=\"color: rgb(240, 240, 242);\"> </span><span style=\"font-weight: bold;\">2 € za každú (aj začatú) hodinu</span><span style=\"font-weight: bold;\">. Poznámka: Od všetkých vyššie uvedených poplatkov sú oslobodení zamestnanci múzeí a vedeckých inštitúcií.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"baoh8vw53yf"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"vlqz3tb7wf"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --center el--2" anim={"5"} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Title className="title-box title-box--left ff--3 fs--22" content={"<span style=\"color: rgb(245, 166, 35);\">❖</span>&nbsp;<span style=\"color: rgb(245, 166, 35);\">Lektorská služba</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"uvod"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"#3d3d3d"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--hvr5">
              
              <Title className="title-box title-box--left ff--3 fs--20" style={{"paddingTop":24}} content={"<span style=\"color: var(--white);\">Lektorovanie výstav a expozície</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 js-anim  --anim5 --anim-s7" anim={"5"} animS={"7"}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/o/7746/68b87d5a00f14b63bf50698530292862.png"} use={"file"} href={"https://cdn.swbpg.com/d/7746/lektorovanie-vystav-a-expozicie.pdf"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":128}} srcSet={""} target={"_blank"} document={"352393e28e0a40f49d5c9785ff71a2a6"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" name={"xc2ph5xt47l"} style={{"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"socialne-siete"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"maxWidth":850}} columns={"4"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim5 --center" anim={"5"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=860x_.png"} svg={false} href={"https://www.facebook.com/Tribe%C4%8Dsk%C3%A9-m%C3%BAzeum-Topo%C4%BE%C4%8Dany-918391598311409/?epa=SEARCH_BOX"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim3" anim={"3"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=860x_.png"} svg={false} href={"https://www.instagram.com/tribecske_muzeum/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim2 --center" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=860x_.png"} svg={false} href={"https://www.youtube.com/channel/UC_9B5K30zlouITbYYku8jjg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim4 --center" anim={"4"}>
              
              <Image src={"https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=860x_.png"} url={"https://tribecskem.podbean.com/"} href={"https://tribecskem.podbean.com/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":40}} srcSet={"https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"ac0az1tc6j4"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l4"}>
        </Column>


        <SeparateLine className="pb--10" name={"xk910v0y66"} style={{"paddingTop":0,"backgroundColor":"rgba(61, 61, 61, 1)"}}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"l2mx2qozse"} style={{"paddingTop":0,"paddingBottom":12.390625,"backgroundColor":"rgba(61, 61, 61, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image className="--left" src={"https://cdn.swbpg.com/t/7746/167f18764f4e4da8b5dd86fe91051308_s=660x_.png"} sizes="100vw" style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/7746/167f18764f4e4da8b5dd86fe91051308_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/167f18764f4e4da8b5dd86fe91051308_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/167f18764f4e4da8b5dd86fe91051308_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"vg6j3ix6al8"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(61, 61, 61, 1)"}}>
        </SeparateLine>


        <Column name={"lc31obpt6ud"} style={{"paddingTop":4,"paddingBottom":5.1328125,"backgroundColor":"rgba(0,0,0,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--14" content={"<span style=\"color: rgb(155, 155, 155);\">© 2023 Tribečské múzeum v Topoľčanoch, všetky práva vyhradené</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}